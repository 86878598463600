import React from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { alteraEmail, alteraSenha, checkLogin, setLoginTrue } from '../redux/usuarioActions'

import { ToastContainer, toast } from 'react-toastify';

import "../App.css"

function Login(props) {


    function login() {
        var valida = true

        if (props.email == '') {
            valida = false
            toast.error("Digite seu E-mail")
        }

        if (props.senha == '') {
            valida = false
            toast.error("Digite sua Senha")
        }

        if (valida = true) {
            props.checkLogin()
        }

    }


    if (props.usuario.login == false) {
        toast.error("Login ou Senha Inválidos")
        props.setLoginTrue()
    }

    return (
        <section className="main_login">
            <section className="login">
                <img src="/logo.png" className="logo" />
                <input type="text" placeholder="E-mail" value={props.email} onChange={(e) => props.alteraEmail(e.target.value)} />
                <input type="password" placeholder="Senha" value={props.senha} onChange={(e) => props.alteraSenha(e.target.value)} />
                <button onClick={() => login()}>LOGIN</button>
            </section>
            <ToastContainer />
        </section>


    )
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario,
        email: state.usuario.email,
        senha: state.usuario.senha,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ alteraEmail, alteraSenha, checkLogin, setLoginTrue }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)