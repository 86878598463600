import React from 'react'

function Avatar(props) {

    const URL = "https://api.moveonsaude.com.br/"
    //const URL = "http://localhost:8080/"

    /*
    if (props.avatar == 0) {
        return (
            <img src="./avatar_0.png" className="avatar_bg" />
        )
    } else if (props.avatar == 1) {
        return (
            <img src="./avatar_1.png" className="avatar_bg" />
        )
    } else if (props.avatar == 2) {
        return (
            <img src="./avatar_2.png" className="avatar_bg" />
        )
    } else if (props.avatar == 3) {
        return (
            <img src="./avatar_3.png" className="avatar_bg" />
        )
    } else if (props.avatar == 4) {
        return (
            <img src="./avatar_4.png" className="avatar_bg" />
        )
    } else if (props.avatar == 5) {
        return (
            <img src="./avatar_5.png" className="avatar_bg" />
        )
    } else if (props.avatar == 6) {
        return (
            <img src="./avatar_6.png" className="avatar_bg" />
        )
    } else if (props.avatar == 7) {
        return (
            <img src="./avatar_7.png" className="avatar_bg" />
        )
    } else if (props.avatar == 8) {
        return (
            <img src="./avatar_6.png" className="avatar_bg" />
        )
    } else {
        return (
            <img src="./avatar_0.png" className="avatar_bg" />
        )
    }
    */
   
    return(
        <img src={ URL + '/uploads/' + props.avatar } className="avatar_bg"  />
    )

}

export default Avatar