import React, { Component } from "react"
import { Redirect } from "react-router-dom";

import Header from './includes/header'
import Footer from './includes/footer'
import Header_Evolucao from './includes/header_evolucao.jsx'
import Avatar from './includes/avatar'

import { connect } from "react-redux"
import { getRanking } from "../redux/rankingReducer"
import { bindActionCreators } from 'redux'

class Ranking extends Component {

    constructor(props) {
        super(props)
        
    }

    componentWillMount(props) {
        this.props.getRanking();
    }

    render() {
        var date = new Date();
        var month = new Array();
        month[0] = "Janeiro";
        month[1] = "Fevereiro";
        month[2] = "Março";
        month[3] = "Abril";
        month[4] = "Maio";
        month[5] = "Junho";
        month[6] = "Julho";
        month[7] = "Agosto";
        month[8] = "Setembro";
        month[9] = "Outubro";
        month[10] = "Novembro";
        month[11] = "Dezembro";
        var month = month[date.getMonth()];

        function render_instagram(user) {
            if ((user.instagram != '') && (user.instagram != null)) {
                return <a href={'http://www.instagram.com.br/' + user.instagram} target="_blank"><img src="./instagram.png" /></a>
            } else {
                return <img src="./instagram_no.png" />
            }
        }

        function render_linkedin(user) {
            if ((user.linkedin != '') && (user.linkedin != null)) {
                return <a href={'https://www.linkedin.com/in/' + user.linkedin} target="_blank"><img src="./linkedin.png" /></a>
            } else {
                return <img src="./linkedin_no.png" />
            }
        }

        const ranking = this.props.ranking.ranking

        function render_ranking() {
            if (ranking.length > 0) {
                return ranking.map(ranking => (
                    <div key={ranking.userID}>
                        <div className="avatar">
                            <Avatar avatar={ranking.avatar} />
                        </div>
                        <div className="info">
                            <h2>Level - {ranking.level} - {ranking.nome}</h2>
                            <p>{ranking.xp} pontos</p>
                        </div>
                        <div className="social">

                            {render_instagram(ranking)}

                            {render_linkedin(ranking)}

                        </div>
                    </div>
                ))
            }
        }

        return (
            <section className="main">
                <Header />
                <section className="ranking">
                    <Header_Evolucao />

                    <h1>Ranking do mês: {month} </h1>
                    <div className="lista_usuarios">
                        {render_ranking()}
                    </div>


                </section>
                <Footer ativo="ranking" />
            </section>
        )
    }
}


function mapStatetoProps(state) {
    return {
        ranking: state.ranking
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getRanking }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(Ranking)