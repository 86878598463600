import axios from "axios"
const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

const INITIAL_STATE = {
    noticias: [],
    titulo: '',
    linha_fina: '',
    data: '',
    conteudo: '',
    imagem: '',
    redirect: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_NOTICIAS":
            return {
                ...state,
                noticias: action.payload
            }
        case "SET_NOTICIA":
            return {
                ...state,
                titulo: action.payload.titulo,
                linha_fina: action.payload.linha_fina,
                data: action.payload.data,
                conteudo: action.payload.conteudo,
                imagem: action.payload.imagem,
                redirect: 1
            }
        case "SET_REDIRECT":
            return {
                ...state,
                redirect: null
            }
        default:
            return state
    }
}

export function getNoticias() {
    return function (dispatch, getState) {
        const request = axios.post(URL + '/noticias/getNoticias').then(
            function (response) {
                dispatch(
                    {
                        type: 'GET_NOTICIAS',
                        payload: response.data
                    }
                )
            }
        )
    }
}

export function setNoticia(noticiaID) {
    return function (dispatch, getState) {
        const request = axios.post( URL + '/noticias/getNoticia', { noticiaID: noticiaID }).then(
            function (response) {
                dispatch(
                    {
                        type: 'SET_NOTICIA',
                        payload: response.data
                    }
                )
            }
        )
    }
}

export function setRedirect(){
    return {
        type: 'SET_REDIRECT',
        payload: null
    }
}