import React, { Component } from "react"
import { Redirect } from "react-router-dom"

import Header from './includes/header'
import Footer from './includes/footer'
import Evolucao from './includes/evolucao.jsx'
import Avatar from './includes/avatar.jsx'
import Premios from './includes/premios.jsx'
import Exercicio from './treinos/exercicio.jsx'

import { connect } from 'react-redux'
import { getEvolucao, checkLogin } from '../redux/usuarioActions'
import { getPremios } from '../redux/premioReducer'
import { bindActionCreators } from 'redux'
import { getTreinos } from "../redux/treinoActions"
import { getNoticias } from "../redux/noticiaReducer"

class Dashboard extends Component {

    constructor(props) {
        super(props)
    }

    componentWillMount(props) {
        this.props.getEvolucao();
        this.props.getPremios();
        this.props.checkLogin();
        this.props.getTreinos();
        this.props.getNoticias();
    }

    render_exercicios() {
        var random = parseInt(Math.random() * (this.props.treinos.length - 0));
        console.log(random)
        if (this.props.treinos.length > 0) {
            return <Exercicio treino={this.props.treinos[random]} />
        }
    }

    render() {
            
        if (this.props.premios.redirect == 1) {
            return <Redirect to={'premio'} />
        }

        if (this.props.usuario.anamnese == 1) {
            return <Redirect to={'anamnese'} />
        }

        return (
            <section className="main">
                <Header />
                <section className="dashboard">
                    <div className="avatar">
                        <img src="./dashboard_mask.png" className="bg_mask" />
                        <Avatar avatar={this.props.usuario.avatar} />
                    </div>

                    <div className="content">
                        <h1>Bom dia, {this.props.usuario.nome}</h1>
                        <Evolucao />

                        {this.render_exercicios()}
                        <Premios premios={this.props.premios} />
                    </div>
                </section>
                <Footer ativo="dashboard" />
            </section>
        )
    }
}

function mapStatetoProps(state) {
    return {
        usuario: state.usuario,
        premios: state.premios,
        treinos: state.treinos.treinos
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getEvolucao, getPremios, checkLogin, getTreinos, getNoticias }, dispatch);
}

export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard)