import React, { Component } from "react"
import ProgressBar from 'react-bootstrap/ProgressBar'
import Avatar from './avatar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getEvolucao } from '../../redux/usuarioActions'

class header_evolucao extends Component {

    constructor(props) {
        super(props)
    }
    
    componentWillMount(props) {
        this.props.getEvolucao();
    }

    render() {
        return (
            <section className="header_evolucao">
                <div className="line">
                    <div className="avatar">
                        <div className="img">
                            <Avatar avatar={this.props.usuario.avatar} />
                        </div>
                        <div className="greetings">Bom dia, <br /><span>{this.props.usuario.nome}</span></div>
                    </div>

                    <div className="coins">
                        <p>Moedas:</p>
                        <div className="flex">
                            <div className="amarela">{this.props.usuario.moeda_1}</div>
                            <div className="verde">{this.props.usuario.moeda_2}</div>
                        </div>
                    </div>
                    <div className="level">
                        level: <span>{this.props.usuario.level}</span>
                    </div>
                </div>

                <ProgressBar now={this.props.usuario.porcentagem_next_level} />
                <p>Faltam {this.props.usuario.xp_next_level} pontos para o próximo nível.</p>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getEvolucao }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(header_evolucao)