import { toast } from 'react-toastify';
import axios from "axios"

const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

const INITIAL_STATE = {
    premios: [],
    premioID: '',
    titulo: '',
    descricao: '',
    foto: '',
    moeda_1: '',
    moeda_2: '',
    redirect: 0,
    premio_resgatado: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_PREMIOS":
            return {
                ...state,
                premios: action.payload
            }
        case "SET_PREMIO":
            return {
                ...state,
                premioID: action.payload.premioID,
                titulo: action.payload.titulo,
                descricao: action.payload.descricao,
                foto: action.payload.foto,
                moeda_1: action.payload.moeda_1,
                moeda_2: action.payload.moeda_2,
                redirect: 1
            }
        case "SET_REDIRECT":
            return {
                ...state,
                redirect: 0
            }
        case "TAKE_PREMIO":
            return {
                ...state,
                premio_resgatado: action.payload
            }
        default:
            return state
    }
}

export function getPremios() {
    return function (dispatch, getState) {
        var state = getState();
        const config = {
            headers: { Authorization: state.usuario.token }
        };
        const request = axios.post(URL + '/premios/getPremios', { 'empresaID': state.usuario.empresaID }, config).then(
            function (response) {

                if (response.data == 'auth') {
                    toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
                    dispatch(
                        {
                            type: "LOGOUT",
                            payload: false
                        }
                    )
                } else {
                    dispatch(
                        {
                            type: 'GET_PREMIOS',
                            payload: response.data
                        }
                    )
                }


            }
        )
    }
}

export function setPremio(premioID) {
    return function (dispatch, getState) {
        var state = getState();
        const config = {
            headers: { Authorization: state.usuario.token }
        };
        const request = axios.post(URL + '/premios/getPremio', { premioID: premioID }, config).then(
            function (response) {

                if (response.data == 'auth') {
                    toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
                    dispatch(
                        {
                            type: "LOGOUT",
                            payload: false
                        }
                    )
                } else {
                    dispatch(
                        {
                            type: 'SET_PREMIO',
                            payload: response.data
                        }
                    )
                }



            }
        )
    }
}

export function takePremio(premioID, usuarioID) {
    console.log(premioID)
    return function (dispatch, getState) {
        var state = getState();
        const config = {
            headers: { Authorization: state.usuario.token }
        };

        const request = axios.post(URL + '/premios/takePremio', { premioID: premioID, usuarioID: usuarioID }, config).then(
            function (response) {

                if (response.data == 'auth') {
                    toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
                    dispatch(
                        {
                            type: "LOGOUT",
                            payload: false
                        }
                    )
                } else {
                    dispatch(
                        [
                            { type: 'TAKE_PREMIO', payload: 1 },
                            getPremios(),
                            { type: 'SET_USUARIO', payload: response.data }
                        ]
                    )
                }

            }
        )
    }
}

export function setPremioResgatado() {
    return {
        type: 'TAKE_PREMIO',
        payload: 0
    }
}

export function setRedirect(premioID) {
    return [
        { type: 'SET_REDIRECT', payload: 0 }
    ]
}