import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'

import Exercicio_realizado from './treinos/exercicio_realizado'
import Intro from "./introduction"
import Cadastro from "./cadastro"
import Dashboard from "./dashboard"
import Treinos from "./treinos"
import Ranking from "./ranking"
import Blog from "./blog"
import Noticia from "./includes/noticia_aberta"
import Perfil from "./perfil"
import Premio from "./includes/premio_aberto"
import Anamnese from './includes/anamnese.jsx'
import Historico_treinos from './treinos/historico_treinos.jsx'


function Content(props) {

    if (props.primeiro_acesso == 0) {
        return (
            <Switch>
                <Route path="/">
                    <Intro />
                </Route>
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path="/cadastro">
                <Cadastro />
            </Route>
            <Route path="/dashboard">
                <Dashboard />
            </Route>
            <Route path="/treinos">
                <Treinos />
            </Route>
            <Route path="/exercicio_realizado">
                <Exercicio_realizado />
            </Route>
            <Route path="/historico_treinos">
                <Historico_treinos />
            </Route>
            <Route path="/ranking">
                <Ranking />
            </Route>
            <Route path="/blog">
                <Blog />
            </Route>
            <Route path="/noticia">
                <Noticia />
            </Route>
            <Route path="/premio">
                <Premio />
            </Route>
            <Route path="/anamnese">
                <Anamnese />
            </Route>
            <Route path="/perfil">
                <Perfil />
            </Route>
            <Route path="/intro">
                <Intro />
            </Route>


            <Route path="/">
                <Dashboard />
            </Route>
        </Switch>
    )
}

function mapStatetoProps(state) {
    return {
        primeiro_acesso: state.usuario.primeiro_acesso
    }
}
export default connect(mapStatetoProps)(Content)