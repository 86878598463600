const INITIAL_STATE = {
    nome: null,
    email: '',
    senha: '',
    usuarioID: null,
    primeiro_acesso: 0,
    telefone: '',
    altura: null,
    avatar: null,
    empresaID: null,
    genero: null,
    gosta_de_fazer: null,
    instagram: null,
    linkedin: null,
    nascimento: null,
    objetivo: null,
    peso: null,
    pratica_esportes: null,
    qual_esporte: null,
    permissao_redes_sociais: null,
    level: null,
    level_up: null,
    moeda_1: null,
    moeda_2: null,
    nivel: null,
    xp_next_level: null,
    porcentagem_next_level: null,
    login: null,
    anamnese: 0,
    token: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_EMAIL":
            return {
                ...state,
                email: action.payload
            }

        case "SET_SENHA":
            return {
                ...state,
                senha: action.payload
            }
        case "SET_LOGIN_FAIL":
            return {
                ...state,
                login: false
            }
        case "SET_LOGIN_TRUE":
            return {
                ...state,
                login: true
            }

        case "SET_USUARIO":
            return {
                ...state,
                nome: action.payload.nome,
                email: action.payload.email,
                usuarioID: action.payload.usuarioID,
                primeiro_acesso: action.payload.primeiro_acesso,
                telefone: action.payload.telefone,
                altura: action.payload.altura,
                avatar: action.payload.avatar,
                empresaID: action.payload.empresaID,
                genero: action.payload.genero,
                gosta_de_fazer: action.payload.gosta_de_fazer,
                instagram: action.payload.instagram,
                linkedin: action.payload.linkedin,
                nascimento: action.payload.nascimento,
                objetivo: action.payload.objetivo,
                peso: action.payload.peso,
                pratica_esportes: action.payload.pratica_esportes,
                qual_esporte: action.payload.qual_esporte,
                permissao_redes_sociais: action.payload.permissao_redes_sociais,
                level: action.payload.level,
                level_up: action.payload.level_up,
                moeda_1: action.payload.moeda_1,
                moeda_2: action.payload.moeda_2,
                nivel: action.payload.nivel,
                anamnese: action.payload.anamnese,
                senha: action.payload.senha,
                token: action.payload.token,
                login: true
            }
        case "SET_ANAMNESE":
            return {
                ...state,
                anamnese: action.payload
            }
        case "SET_PRIMEIRO_ACESSO":
            return {
                ...state,
                primeiro_acesso: action.payload
            }
        case "SET_GOSTA_FAZER":
            return {
                ...state,
                gosta_de_fazer: action.payload
            }
        case "SET_PRATICA_ESPORTES":
            return {
                ...state,
                pratica_esportes: action.payload
            }
        case "SET_QUAL_ESPORTE":
            return {
                ...state,
                qual_esporte: action.payload
            }
        case "SET_PESO":
            return {
                ...state,
                peso: action.payload
            }
        case "SET_ALTURA":
            return {
                ...state,
                altura: action.payload
            }
        case "SET_GENERO":
            return {
                ...state,
                genero: action.payload
            }
        case "SET_NASCIMENTO":
            return {
                ...state,
                nascimento: action.payload
            }
        case "SET_OBJETIVO":
            return {
                ...state,
                objetivo: action.payload
            }

        case "SET_AVATAR":
            return {
                ...state,
                avatar: action.payload
            }

        case "SET_TELEFONE":
            return {
                ...state,
                telefone: action.payload
            }

        case "SET_NOME":
            return {
                ...state,
                nome: action.payload
            }

        case "SET_INSTAGRAM":
            return {
                ...state,
                instagram: action.payload
            }

        case "SET_LINKEDIN":
            return {
                ...state,
                linkedin: action.payload
            }

        case "SET_PERMISSAO":
            return {
                ...state,
                permissao_redes_sociais: action.payload
            }
        case 'GET_EVOLUCAO':
            return {
                ...state,
                xp_next_level: action.payload.xp_next_level,
                porcentagem_next_level: action.payload.porcentagem_next_level,
            }
        case "SET_LEVEL_UP":
            return {
                ...state,
                level_up: action.payload
            }
        case "LOGOUT":
            return {
                state: null
            }
        default:
            return state
    }
}