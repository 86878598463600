import React from "react"
import Slider from "react-slick";
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { changePrimeiro_acesso } from "../redux/usuarioActions"


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css"

import { Animated } from "react-animated-css";

function introduction(props) {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <section className="main_login">
            <Slider {...settings} className="intro_slider">
                <div>
                    <div className="content">
                        <img src="../logo.png" className="logo" />
                        <p>A move on traz uma metodologia inovadora e exclusiva para você revolucionar o seu estilo de vida com a aquisição de hábitos saudáveis. Atividades físicas, meditações, leitura, respiração, aulas ao vivo, dicas nutricionais e diversos outros conteúdos relevantes associados ao bem-estar físico e mental, tudo em um só lugar.</p>
                    </div>
                    <img src="./intro_bg_1.jpg" className="intro_bg" />
                </div>
                <div>
                    <div className="content">
                        <img src="./logo.png" className="logo" />
                        <p>Para facilitar o caminho rumo a uma vida mais saudável, utilizamos a gamificação (estímulo por meio de jogos) e promovemos competições amistosas nas quais os participantes que obtiverem melhores resultados serão premiados. Criamos um ambiente divertido e desafiador para que os usuários se tornem mais engajados, motivados e integrados.</p>
                    </div>
                    <img src="./intro_bg_2.jpg" className="intro_bg" />
                </div>
                <div>
                    <div className="content">
                        <img src="./logo.png" className="logo" />
                        <p>Ao praticar as atividades, cumprir metas e receber recompensas o usuário poderá ainda colaborar com o meio ambiente. A Move on e a ONG Iniciativa verde, estão juntas no combate a crise climática e na preservação dos nossos mananciais.</p>
                        <Link to="./cadastro"><button onClick={() => props.changePrimeiro_acesso()} className="default">COMEÇAR</button></Link>
                    </div>
                    <img src="../intro_bg_3.jpg" className="intro_bg" />
                </div>
            </Slider>
        </section>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changePrimeiro_acesso }, dispatch);
}

export default connect(null, mapDispatchToProps)(introduction)