import { combineReducers } from 'redux'
import usuarioReducer from './usuarioReducer'
import treinoReducer from './treinoReducer'
import rankingReducer from './rankingReducer'
import premioReducer from './premioReducer'
import noticiaReducer from './noticiaReducer'
import anamneseReducer from './anamneseReducer'

const rootReducers = combineReducers({
    usuario: usuarioReducer,
    treinos: treinoReducer,
    ranking: rankingReducer,
    premios: premioReducer,
    noticias: noticiaReducer,
    anamnese: anamneseReducer
})

export default rootReducers