import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alteraPeso, alteraAltura, alteraGenero, alteraNascimento, alteraObjetivo } from "../../redux/usuarioActions"

import "../../App.css"
import ReactInputDateMask from 'react-input-date-mask';
import { ToastContainer, toast } from 'react-toastify';

function Etapa2(props) {

    function mudar_de_tela() {
        var valida = true;

        if (props.altura == null) {
            valida = false;
            toast.error("Digite sua altura");
        }

        if (props.peso == null) {
            valida = false;
            toast.error("Digite seu peso");
        }

        if (props.nascimento == null) {
            valida = false;
            toast.error("Digite sua data de nascimento");
        }

        if (valida == true) {
            props.mudaTela(3)
        }


    }

    return (

        <section className="main_login">
            <section className="cadastro">
                <section className="paginador">
                    <div className="risco">1</div>
                    <div className="ativo risco">2</div>
                    <div>3</div>
                </section>
                <div className="content">
                    <h1>Conte mais sobre você.</h1>
                    <fieldset>
                        <label>Altura</label>
                        <input type="text" onKeyPress={(event) => { if (!/[.,0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Qual a sua altura?" value={props.altura} onChange={(e) => props.alteraAltura(e.target.value)}  />
                        <label>Peso</label>
                        <input type="text" onKeyPress={(event) => { if (!/[.,0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Qual o seu peso?" value={props.peso} onChange={(e) => props.alteraPeso(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label>Gênero</label>
                        <select onChange={(e) => props.alteraGenero(e.target.value)}>
                            <option value="1">Homem</option>
                            <option value="2">Mulher</option>
                        </select>
                        <label>Data de nascimento</label>
                        <ReactInputDateMask mask='dd/mm/yyyy' value={props.nascimento} placeholder="Data de Nacimento" showMaskOnFocus={true} showMaskOnHover={true} onChange={props.alteraNascimento} />;
                    </fieldset>
                    <fieldset>
                        <label>Qual seu Principal Objetivo</label>
                        <select onChange={(e) => props.alteraObjetivo(e.target.value)}>
                            <option value="1">Emagrecer</option>
                            <option value="2">Saúde</option>
                        </select>
                    </fieldset>
                    <button className="default" onClick={() => mudar_de_tela()} >PRÓXIMO</button>
                </div>
            </section>
            <ToastContainer />
        </section>
    )
}

function mapStateToProps(state) {
    return {
        altura: state.usuario.altura,
        peso: state.usuario.peso,
        genero: state.usuario.genero,
        nascimento: state.usuario.nascimento,
        objetivo: state.usuario.objetivo
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ alteraPeso, alteraAltura, alteraGenero, alteraNascimento, alteraObjetivo }, dispatch)
}



export default connect(mapStateToProps, mapDispatchToProps)(Etapa2)