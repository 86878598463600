import React from "react"
import { Link } from 'react-router-dom'

function footer(props) {

    let dashboard_style = null
    let treinos_style = null
    let ranking_style = null
    let blog_style = null
    let perfil_style = null

    if (props.ativo == "dashboard") {
        dashboard_style = "ativo"
    }

    if (props.ativo == "treinos") {
        treinos_style = "ativo"
    }

    if (props.ativo == "ranking") {
        ranking_style = "ativo"
    }

    if (props.ativo == "blog") {
        blog_style = "ativo"
    }

    if (props.ativo == "perfil") {
        perfil_style = "ativo"
    }


    return (
        <footer>
            <div className={dashboard_style}>
                <Link to="/dashboard"><img src="./icon_1.png" /></Link>
            </div>
            <div className={treinos_style}>
                <Link to="/treinos"><img src="./icon_2.png" /></Link>
            </div>
            <div className={ranking_style}>
                <Link to="/ranking"><img src="./icon_3.png" /></Link>
            </div>
            <div className={blog_style}>
                <Link to="/blog"><img src="./icon_4.png" /></Link>
            </div>
            <div className={perfil_style}>
                <Link to="/perfil"><img src="./icon_5.png" /></Link>
            </div>
        </footer>
    )
}

export default footer