import React, { Component } from "react"
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import 'react-toastify/dist/ReactToastify.css'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Content from "./components/content"
import Login from "./components/login"

import { valida_usuario_logado } from './redux/usuarioActions'

class App extends Component {

  constructor(props) {
    super(props)
  }

  componentWillMount(props) {
    this.props.valida_usuario_logado();
  }

  

  render() {   
    if (this.props.usuarioID == null) {
      return (
        <Router>
          <Login />
          <ToastContainer />
        </Router>
      )
    } else {
      return (
        <Router>
          <Content />
          <ToastContainer />
        </Router>
      )
    }
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ valida_usuario_logado }, dispatch);
}

function mapStateToProps(state) {
  return {
    usuarioID: state.usuario.usuarioID
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);