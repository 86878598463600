import React, { Component } from "react"
import { Link } from 'react-router-dom'

import Header from './includes/header'
import Footer from './includes/footer'
import Header_Evolucao from './includes/header_evolucao.jsx'
import Exercicio from './treinos/exercicio'

import { bindActionCreators } from "redux"
import { connect } from 'react-redux'
import { getTreinos, getTipos, filtraTreinos, getHistoricoTreinos } from "../redux/treinoActions"
import { getNoticias } from "../redux/noticiaReducer"


class Treinos extends Component {

    constructor(props) {
        super(props)
    }

    render_tipos_treinos(treinos) {
        const tipos = treinos.tipos
        if (tipos.length > 0) {
            return tipos.map(tipo => (
                <option>{ tipo.info }</option>
            ))
        }
    }

    render_exercicios(treinos) {
        const exercicios = treinos.treinos

        if (exercicios.length > 0) {
            return exercicios.map(treino => (
                <Exercicio key={treino.treinoID} treino={treino} />
            ))
        } 
        
    }

    componentWillMount(props) {
        this.props.getTreinos();
        this.props.getTipos();
        this.props.getHistoricoTreinos();
        this.props.getNoticias();
    }

    render() {
        return (
            <section className="main">
                <Header />
                <Header_Evolucao />
                <section className="treinos">
                    <div className="linha direita">
                        <select className="filtra_estilos" onChange={ (e) => this.props.filtraTreinos(e.target.value) }>
                            <option selected disabled>Selecione um tipo de treino</option>
                            {this.render_tipos_treinos(this.props.treinos)}
                            <option>Todos os Treinos</option>
                        </select>
                    </div>
                    <div className="linha">
                        {this.render_exercicios(this.props.treinos)}
                    </div>
                    <div className="linha">
                        <Link to="historico_treinos"><button className="default">Histórico de Treinos</button></Link>
                    </div>
                </section>
                <Footer ativo="treinos" />
            </section >
        )
    }
}

function mapstateToProps(state) {
    return {
        usuario: state.usuario,
        treinos: state.treinos
    }
}

function mapDipsatch(dispatch) {
    return bindActionCreators({ getTreinos, getTipos, filtraTreinos, getHistoricoTreinos, getNoticias }, dispatch)
}


export default connect(mapstateToProps, mapDipsatch)(Treinos)