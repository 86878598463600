import axios from 'axios'
const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

export function valida_usuario_logado() {
	let usuario = JSON.parse(localStorage.getItem('usuario'))
	if (usuario) {
		return {
			type: "SET_USUARIO",
			payload: usuario
		}
	} else {
		return {
			type: null,
			payload: null
		}
	}
}



export function alteraEmail(email) {
	console.log(email)
	return {
		type: "SET_EMAIL",
		payload: email
	}
}

export function alteraSenha(senha) {
	return {
		type: "SET_SENHA",
		payload: senha
	}
}

export function checkLogin() {
	return function (dispatch, getState) {
		var state = getState();
		const request = axios.post(URL + '/API/' + 'auth', { 'email': state.usuario.email, 'senha': state.usuario.senha }).then(
			function (response) {
				if (response.data.clean_local_storage == 1) {
					localStorage.clear();
				}
				dispatch(setUsuario(response.data))
			});
	}
}

export function setAnamnese(value) {
	return {
		type: "SET_ANAMNESE",
		payload: value
	}
}

export function setUsuario(usuario) {
	if (usuario == false) {
		return {
			type: "SET_LOGIN_FAIL",
			payload: false
		}
	} else {
		localStorage.setItem('usuario', JSON.stringify(usuario));
		return {
			type: "SET_USUARIO",
			payload: usuario
		}
	}
}

export function setLoginTrue() {
	return {
		type: "SET_LOGIN_TRUE",
		payload: true
	}
}

export function changePrimeiro_acesso() {
	return [
		{ type: "SET_PRIMEIRO_ACESSO", payload: 1 },
		updateUsuario()
	]
	
}

export function alteraGostaFazer(value) {
	return {
		type: "SET_GOSTA_FAZER",
		payload: value
	}
}

export function alteraPraticaEsportes(value) {
	return {
		type: "SET_PRATICA_ESPORTES",
		payload: value
	}
}

export function alteraTelefone(value) {
	return {
		type: "SET_TELEFONE",
		payload: value
	}
}

export function alteraNome(value) {
	return {
		type: "SET_NOME",
		payload: value
	}
}

export function alteraQualEsporte(value) {
	return {
		type: "SET_QUAL_ESPORTE",
		payload: value
	}
}


export function alteraPeso(value) {
	return {
		type: "SET_PESO",
		payload: value
	}
}

export function alteraAltura(value) {
	return {
		type: "SET_ALTURA",
		payload: value
	}
}

export function alteraGenero(value) {
	return {
		type: "SET_GENERO",
		payload: value
	}
}

export function alteraNascimento(value) {
	return {
		type: "SET_NASCIMENTO",
		payload: value
	}
}

export function alteraObjetivo(value) {
	return {
		type: "SET_OBJETIVO",
		payload: value
	}
}

export function alteraAvatar(value) {
	console.log(value.current)
	return {
		type: "SET_AVATAR",
		payload: value.current
	}
}

export function alteraInstagram(value) {
	return {
		type: "SET_INSTAGRAM",
		payload: value
	}
}

export function alteraLinkedin(value) {
	return {
		type: "SET_LINKEDIN",
		payload: value
	}
}

export function alteraPermissao(value) {
	return {
		type: "SET_PERMISSAO",
		payload: value
	}
}

export function updateUsuario() {
	return function (dispatch, getState) {
		var state = getState();

		const request = axios.post(URL + '/API/updateUsuario', { 'usuario': state.usuario },).then(
			function (response) {
				dispatch(setUsuario(response.data))
			});
	}
}

export function getEvolucao() {
	return function (dispatch, getState) {
		var state = getState();
		const request = axios.post(URL + '/usuarios/evolucao', { usuarioID: state.usuario.usuarioID }).then(
			function (response) {
				dispatch(
					{
						type: 'GET_EVOLUCAO',
						payload: response.data
					}
				)
			}
		)
	}
}

export function setLevelUp(value) {
	return [
		{ type: 'SET_LEVEL_UP', payload: value },
		//localStorage.clear(),
		updateUsuario()
	]
}


export function logout() {
	localStorage.removeItem('usuario')
	return {
		type: 'LOGOUT',
		payload: false
	}
}