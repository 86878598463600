import React from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { setPremio } from "../../redux/premioReducer"

function Premios(props) {

    function set_premio(premioID) {
        props.setPremio(premioID);
    }

    const URL = "https://api.moveonsaude.com.br/"
    //const URL = "http://localhost:8080/"

    function render_premios(premios) {
        if (premios.length > 0) {
            return premios.map(premio => {
                if (premio.usuarioID == 0) {
                    return <div className="line" key={premio.premioID}>
                        <div className="img" onClick={() => set_premio(premio.premioID)} >
                            <img src={ URL + 'uploads/' + premio.foto} />
                        </div>
                        <p onClick={() => set_premio(premio.premioID)}>{premio.titulo}</p>
                        <div className="moedas">
                            <div className="amarela">{premio.moeda_1}</div>
                            <div className="verde">{premio.moeda_2}</div>
                        </div>
                    </div>
                } else {
                    return <div className="line" key={premio.premioID}>
                        <div className="img">
                            <img src={ URL + 'uploads/' + premio.foto} />
                        </div>
                        <p>{premio.titulo}
                            - Resgatado: {premio.nome}</p>
                    </div>
                }
            })
        }
    }

    return (
        <div className="lista_premios">
            <h1>RESGATE SUAS MOEDAS</h1>
            {render_premios(props.premios.premios)}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setPremio }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Premios)