import React, { Component } from "react"

import Header from './includes/header'
import Footer from './includes/footer'
import Header_Evolucao from './includes/header_evolucao.jsx'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, alteraNome, alteraEmail, alteraAvatar, alteraInstagram, alteraLinkedin, alteraPermissao, updateUsuario, alteraTelefone } from "../redux/usuarioActions"

import "../App.css"
import { ToastContainer, toast } from 'react-toastify';
import InputMask from 'react-input-mask';

const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

class Perfil extends Component {

    modificaUsuario() {

        var valida = true

        if (this.props.usuario.nome == '') {
            valida = false
            toast.error("Digite seu Nome")
        }

        if (this.props.usuario.telefone == '') {
            valida = false
            toast.error("Digite seu Telefone")
        }

        if (valida == true) {
            this.props.updateUsuario()
            toast("Perfil modificado!");
        }
    }

    render() {
        return (
            <section className="main">
                <Header />
                <Header_Evolucao />
                <section className="perfil">

                    <div className="content">
                        <div className="avatar">
                            <img src={ URL + '/uploads/' + this.props.usuario.avatar } className="avatar_bg"  /> 
                        </div>
                        <fieldset className="invade_top">
                            <input type="text" placeholder="Nome" value={this.props.usuario.nome} onChange={(e) => this.props.alteraNome(e.target.value)} />
                            <label>E-mail</label>
                            <input type="email" placeholder="E-mail" value={this.props.usuario.email} readonly='true' />
                            <label>Telefone</label>
                            <InputMask mask="(99) 9 9999-9999" value={this.props.usuario.telefone} onChange={(e) => this.props.alteraTelefone(e.target.value)} />;
                            <label>Instagram</label>
                            <input type="text" placeholder="Instagram" value={this.props.usuario.instagram} onChange={(e) => this.props.alteraInstagram(e.target.value)} />
                            <label>Linkedin</label>
                            <input type="text" placeholder="Linkedin" value={this.props.usuario.linkedin} onChange={(e) => this.props.alteraLinkedin(e.target.value)} />
                            <button class="default" onClick={() => this.modificaUsuario()}>MODIFICAR</button>
                            <button class="logout" onClick={() => this.props.logout()}>LOGOUT</button>
                        </fieldset>
                    </div>

                </section>
                <Footer ativo="perfil" />
                <ToastContainer />
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ alteraNome, alteraEmail, alteraAvatar, alteraInstagram, alteraLinkedin, alteraPermissao, updateUsuario, alteraTelefone, logout }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Perfil)