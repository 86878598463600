import axios from "axios"

const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

const INITIAL_STATE = {
    ranking:[]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_RANKING":
            return {
                ...state,
                ranking: action.payload
            }
        default:
            return state
    }
}

export function getRanking() {
    

    return function (dispatch, getState) {
        var state = getState();
        const request = axios.post(URL + 'usuarios/getRanking', { 'empresaID': state.usuario.empresaID } ).then(
            function (response) {
                dispatch(
                    {
                        type: 'SET_RANKING',
                        payload: response.data
                    }
                )
            }
        )
    }
}