import axios from 'axios'
const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

const INITIAL_STATE = {
    abdominal: 0,
    agachamento: 0,
    peso: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ABDOMINAL":
            return {
                ...state,
                abdominal: action.payload
            }
        case "SET_AGACHAMENTO":
            return {
                ...state,
                agachamento: action.payload
            }
        case "SET_PESO":
            return {
                ...state,
                peso: action.payload
            }
        case "CLEAN":
            return {
                abdominal: 0,
                agachamento: 0,
                peso: 0
            }
        default:
            return state
    }
}

export function setAbdominal(value) {
    return {
        type: 'SET_ABDOMINAL',
        payload: value
    }
}

export function setAgachamento(value) {
    return {
        type: 'SET_AGACHAMENTO',
        payload: value
    }
}

export function setPeso(value) {
    return {
        type: 'SET_PESO',
        payload: value
    }
}

export function sendAnamnese() {
    return function (dispatch, getState) {
        var state = getState();
        const request = axios.post(URL + '/usuarios/anamnese',
            {
                'usuarioID': state.usuario.usuarioID,
                'peso': state.anamnese.peso,
                'abdominal': state.anamnese.abdominal,
                'agachamento': state.anamnese.agachamento
            }).then(
                function (response) {
                    dispatch(
                        [
                            { type: 'SET_ANAMNESE', payload: 0 },
                            { type: 'CLEAN', payload: 0 }
                        ]   
                    )
                }
            )
    }
}
