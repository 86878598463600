import React from 'react'
import { Link } from "react-router-dom"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alteraAvatar, alteraInstagram, alteraLinkedin, alteraPermissao, updateUsuario } from "../../redux/usuarioActions"
import "../../App.css"

function Etapa3(props) {

    const URL = "https://api.moveonsaude.com.br/"
    //const URL = "http://localhost:8080/"

    return (
        <section className="main_login">
            <section className="cadastro">
                <section className="paginador">
                    <div className="risco">1</div>
                    <div className="risco">2</div>
                    <div className="ativo">3</div>
                </section>
                <div className="content">
                    <h1>Redes Sociais</h1>
                    <div className="avatar">
                        <div className="imagem">
                            <img src={ URL + '/uploads/' + props.avatar } />
                        </div>
                    </div>
                    <fieldset className="invade_top">
                        <input type="text" placeholder="@instagram" value={props.instagram} onChange={(e) => props.alteraInstagram(e.target.value)} />
                        <input type="text" placeholder="@linkedin" value={props.linkedin} onChange={(e) => props.alteraLinkedin(e.target.value)} />
                        <br />
                        <div className="check">
                        <input type="checkbox" value="1" onChange={(e) => props.alteraPermissao(e.target.value)} />
                        <p>Permito que minhas redes sociais fiquem visíveis  a outros usuários</p>
                        </div>
                    </fieldset>
                    <Link to="./dashboard"><button className="default" onClick={() => props.updateUsuario()}>CONTINUAR</button></Link>
                </div>
            </section>
        </section>
    )
}

function mapStateToProps(state) {
    return {
        avatar: state.usuario.avatar,
        instagram: state.usuario.instagram,
        linkedin: state.usuario.linkedin,
        permissao: state.usuario.permissao
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ alteraAvatar, alteraInstagram, alteraLinkedin, alteraPermissao, updateUsuario }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Etapa3)