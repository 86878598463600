import { toast } from 'react-toastify';
import axios from 'axios'
const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"


export function getTipos() {
	return function (dispatch, getState) {
		var state = getState();
		const request = axios.post(URL + '/treinos/lista_tipos_de_treinos', { usuarioID: state.usuario.usuarioID }).then(
			function (response) {
				dispatch(
					{
						type: 'GET_TIPOS',
						payload: response.data
					}
				)
			}
		)
	}
}

export function filtraTreinos(tipo) {
	return function (dispatch, getState) {
		var state = getState();
		const request = axios.post(URL + '/treinos/lista_treinos_por_tipo', { usuarioID: state.usuario.usuarioID, tipo: tipo }).then(
			function (response) {
				dispatch(
					{
						type: 'GET_TREINOS',
						payload: response.data
					}
				)
			}
		)
	}
}

export function getTreinos() {
	return function (dispatch, getState) {
		var state = getState();
		const config = {
			headers: { Authorization: state.usuario.token }
		};

		const request = axios.post(URL + '/treinos/get_treinos', { usuarioID: state.usuario.usuarioID }, config).then(
			function (response) {

				if (response.data == '') {
					toast.success('Você ja concluiu seus treinos de hoje', { toastId: 'dasasdf' })
				}

				if (response.data == 'auth') {
					toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
					dispatch(
						{
							type: "LOGOUT",
							payload: false
						}
					)
				} else {
					dispatch(
						{
							type: 'GET_TREINOS',
							payload: response.data
						}
					)
				}
			}
		)
	}
}

export function getHistoricoTreinos() {
	return function (dispatch, getState) {
		var state = getState();
		const request = axios.post(URL + '/treinos/lista_historico_de_treinos', { usuarioID: state.usuario.usuarioID }).then(
			function (response) {
				dispatch(
					{
						type: 'GET_HISTORICO_TREINOS',
						payload: response.data
					}
				)
			}
		)
	}
}



export function setDataInicio(data_inicio) {
	return
}

export function setDataFim(data_fim) {
	return {
		type: "SET_DATA_FIM",
		payload: data_fim
	}
}


export function treinoIniciado(treinoID, data_inicio) {
	return function (dispatch, getState) {
		var state = getState();
		const config = {
			headers: { Authorization: state.usuario.token }
		};

		const request = axios.post(URL + '/treinos/treino_iniciado', {
			usuarioID: state.usuario.usuarioID,
			treinoID: treinoID,
			data_inicio: data_inicio
		}, config).then(
			function (response) {
				if (response.data == 'auth') {
					toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
					dispatch(
						{
							type: "LOGOUT",
							payload: false
						}
					)
				} else {
					dispatch(
						[
							{ type: "SET_DATA_INICIO", payload: data_inicio },
							{ type: "SET_TREINO_HISTORICO_ID", payload: response.data.treino_historicoID },
							{ type: "SET_PORCENTAGEM", payload: response.data.porcentagem / 100 },
						]
					)
				}
			}
		)
	}
}

export function treino_atualizado(porcentagem) {
	return function (dispatch, getState) {
		var state = getState();
		const config = {
			headers: { Authorization: state.usuario.token }
		};

		const request = axios.post(URL + '/treinos/treino_atualizado', {
			treino_historicoID: state.treinos.treino_historicoID,
			porcentagem: porcentagem
		}, config).then(
			function (response) {
				if (response.data == 'auth') {
					toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
					dispatch(
						{
							type: "LOGOUT",
							payload: false
						}
					)
				} else {
					dispatch(
						[
							{ type: "SET_PORCENTAGEM", payload: porcentagem },
						]
					)
				}
			}
		)
	}
}

export function treinoRealizado(data_fim) {
	return function (dispatch, getState) {
		var state = getState();
		const config = {
			headers: { Authorization: state.usuario.token }
		};

		const request = axios.post(URL + '/treinos/treino_realizado', {
			usuarioID: state.usuario.usuarioID,
			treino_historicoID: state.treinos.treino_historicoID,
			data_fim: data_fim
		}, config).then(
			function (response) {
				if (response.data == 'auth') {
					toast.error('Token Expirado. Por favor, faça login novamente.', { toastId: 'dasasdf' })
					dispatch(
						{
							type: "LOGOUT",
							payload: false
						}
					)
				} else {
					dispatch(
						[
							{ type: 'SET_TREINO_REALIZADO', payload: response.data['historico'] },
							{ type: 'SET_USUARIO', payload: response.data['usuario'] }
						]
	
					)
				}
			}
		)
	}
}

export function setlocalStorage() {
	return function (dispatch, getState) {
		var state = getState();
		localStorage.setItem("treinos", JSON.stringify(state.treinos.treinos));
	}

}

/*
export function setDuration(treinoID, percentage) {
	return [
		{ type: 'SET_DURATION', payload: { treinoID: treinoID, percentage: percentage } },
		setlocalStorage()
	]
}
*/

export function cleanState() {
	return {
		type: 'CLEAN_STATE',
		payload: null
	}
}


export function setVisibility(visibility) {
	return {
		type: 'SET_VISIBILITY',
		payload: visibility
	}
}

export function setAvaliacao(avaliacao) {
	// @TODO: enviar a avaliação para a API
	return {
		type: 'SET_AVALIACAO',
		payload: avaliacao
	}
}


