import React from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { setRedirect, takePremio, setPremioResgatado } from "../../redux/premioReducer"
import { Redirect } from "react-router-dom"

import Header from './header'
import Footer from './footer'
import Header_Evolucao from './header_evolucao.jsx'


const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"


function Premio_aberto(props) {

    let[redirect, setRedirect] = React.useState(null);

    React.useEffect(() => {
        props.setRedirect();
    }, []);


    function pode_resgatar_premio() {
        if (parseInt(props.premio.moeda_1) <= parseInt(props.usuario.moeda_1)) {
            return <button className="default" onClick={() => props.takePremio(props.premio.premioID, props.usuario.usuarioID)}>Resgatar Premio</button>
        } else {
            return <h2>Ainda faltam {props.premio.moeda_1 - props.usuario.moeda_1} moedas para você conseguir resgatar este premio</h2>
        }
    }

    function voltar(){
        props.setPremioResgatado(0)
        setRedirect('dashboard')
    }


    if (props.premio.premio_resgatado == 1) {
        return (
            
            <section className="main">
                <Header />
                <Header_Evolucao />
                <section className="premio_aberto">
                    <section className="content">
                        <section className="imagem">
                            <img src={ URL + '/uploads/' + props.premio.foto} />
                        </section>
                        <h1>Parabéns, você resgatou o {props.premio.titulo}</h1>
                        <p>Você receberá em seu e-mail um voucher com todos os detalhes</p>
                        <button className="default" onClick={ () => voltar() }>Voltar</button>
                    </section>
                </section>
                <Footer />
            </section>
        )
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }
    
    return (
        <section className="main">
            <Header />
            <Header_Evolucao />
            <section className="premio_aberto">
                <section className="imagem">
                    <img src={ URL + '/uploads/' + props.premio.foto} />
                </section>
                <section className="content">
                    <h1>{props.premio.titulo} </h1>
                    <p>{props.premio.descricao} </p>
                    {pode_resgatar_premio()}
                </section>
            </section>
            <Footer />
        </section>
    )
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario,
        premio: state.premios
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setRedirect, takePremio, setPremioResgatado }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Premio_aberto)