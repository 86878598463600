import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setRedirect } from "../../redux/noticiaReducer"

import Header from './header'
import Footer from './footer'
import Header_Evolucao from './header_evolucao.jsx'

const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

function Noticia_aberta(props) {

    React.useEffect(() => {
        props.setRedirect();
    }, []);

    return (
        <section className="main">
        <Header />
        <Header_Evolucao />
        <section className="noticia_aberta">
            <div className="imagem">
                <img src={ URL + '/uploads/' + props.noticia.imagem} />
            </div>
            <div className="content">
                <h1>{props.noticia.titulo}</h1>
                <h2>{props.noticia.linha_fina}</h2>
                <p><div dangerouslySetInnerHTML={{__html: props.noticia.conteudo  }} /></p>
            </div>
        </section>
        <Footer ativo="blog" />
    </section>

       
    )
}

function mapStateToProps(state) {
    return {
        noticia: state.noticias
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setRedirect }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Noticia_aberta)