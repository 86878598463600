const INITIAL_STATE = {
    treinos: [],
    historico: [],
    tipos: [],
    treino_historicoID: null,
    porcentagem: null,
    visibility: false,
    data_inicio: null,
    data_fim: null,
    avaliacao: null,
    aprovado: null,
    xp: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_TREINOS":
            return {
                ...state,
                treinos: action.payload
            }
        case "GET_TIPOS":
            return {
                ...state,
                tipos: action.payload
            }
        case "GET_HISTORICO_TREINOS":
            return {
                ...state,
                historico: action.payload
            }
        case "CLEAN_STATE":
            return {
                ...state,
                data_fim: null,
                avaliacao: null,
                aprovado: null,
                xp: null
            }
        case "SET_VISIBILITY":
            return {
                ...state,
                visibility: action.payload
            }
        case "SET_DATA_INICIO":
            return {
                ...state,
                data_inicio: action.payload
            }
        case "SET_TREINO_HISTORICO_ID":
            return {
                ...state,
                treino_historicoID: action.payload
            }
        case "SET_DATA_FIM":
            return {
                ...state,
                data_fim: action.payload
            }
        case "SET_AVALIACAO":
            return {
                ...state,
                avaliacao: action.payload
            }
        case "SET_TREINO_REALIZADO":
            return {
                ...state,
                xp: action.payload.xp
            }
        case "SET_PORCENTAGEM":
            return {
                ...state,
                porcentagem: action.payload
            }
        /*
    case "SET_DURATION":
        var treinos = state.treinos

        var treinos_modificado = treinos.map(function (treino) {
            console.log(action.payload.treinoID)
            if (treino.treinoID == action.payload.treinoID) {
                treino.percentage = parseInt(action.payload.percentage * 100)
            }
            return treino
        });
    */
        default:
            return state
    }
}