import React from "react"

function header(props){
    return(
        <header>
            <img src="./logo_nav.png" />
        </header>
    )
}

export default header