import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alteraGostaFazer, alteraPraticaEsportes, alteraQualEsporte } from "../../redux/usuarioActions"

import "../../App.css"
import { Animated } from "react-animated-css";

import { ToastContainer, toast } from 'react-toastify';


function Etapa1(props) {

    function mudar_de_tela() {
        var valida = true

        console.log(props.gosta_de_fazer)

        if (props.gosta_de_fazer == null) {
            valida = false
            toast.error("O que você gosta de fazer?")
        }

        if (props.pratica_esportes == null) {
            valida = false
            toast.error("Você pratica esportes?")
        }

        if (valida == true) {
            props.mudaTela(2)
        }

    }


    return (
        <section className="main_login">
            <section className="cadastro">
                <section className="paginador">
                    <div className="ativo risco">1</div>
                    <div className="risco">2</div>
                    <div>3</div>
                </section>
                <div className="content">
                    <h1>Conte mais sobre você.</h1>
                    <fieldset>
                        <label>O que você mais gosta de fazer?</label>
                        <select onChange={(e) => props.alteraGostaFazer(e.target.value)}>
                            <option value="0" selected disabled>Selecione</option>
                            <option value="1">Tocar instrumentos musicais</option>
                            <option value="2">Praticar esportes</option>
                            <option value="3">Ler</option>
                            <option value="4">Assistir filmes</option>
                            <option value="5">Frequentar aulas de teatro</option>
                            <option value="6">Pintar</option>
                            <option value="7">Cozinhar</option>
                            <option value="8">Dançar</option>
                            <option value="9">Viajar</option>
                            <option value="10">Escrever</option>
                            <option value="11">Meditar</option>
                            <option value="12">Visitar museus</option>
                            <option value="13">Fotografar</option>
                            <option value="14">Velejar</option>
                            <option value="15">Pescar</option>
                            <option value="16">Tocar DJ</option>
                            <option value="17">Ir a restaurantes</option>
                            <option value="18">Passear no shopping</option>
                            <option value="19">Estudar</option>
                        </select>
                    </fieldset>
                    <fieldset>
                        <label>Você ja praticou ou pratica algum esporte?</label>
                        <select onChange={(e) => props.alteraPraticaEsportes(e.target.value)}>
                            <option value="0" selected disabled>Selecione</option>
                            <option value="1">Nunca pratiquei</option>
                            <option value="2">Pratique, mas estou parado</option>
                            <option value="3">Pratico esportes com frequencia</option>
                        </select>
                    </fieldset>
                    <fieldset>
                        <label>Qual esporte?</label>
                        <select onChange={(e) => props.alteraQualEsporte(e.target.value)}>
                            <option value="0" selected disabled>Selecione</option>
                            <option value="1">Tênis</option>
                            <option value="2">Basquete</option>
                            <option value="3">Corrida</option>
                            <option value="4">Futebol</option>
                            <option value="5">Ginástica</option>
                            <option value="6">Natação</option>
                            <option value="7">Skate</option>
                            <option value="8">Surfe</option>
                            <option value="9">Vôlei</option>
                            <option value="10">Luta</option>
                            <option value="11">Musculação </option>
                            <option value="12">Triathlon</option>
                            <option value="13">Outros</option>
                        </select>
                    </fieldset>
                    <button className="default" onClick={() => mudar_de_tela()}>PRÓXIMO</button>
                </div>
            </section>

            <ToastContainer />
        </section>

    )
}

function mapStateToProps(state) {
    return {
        pratica_esportes: state.usuario.pratica_esportes,
        gosta_de_fazer: state.usuario.gosta_de_fazer,
        qual_esporte: state.usuario.qual_esporte,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ alteraGostaFazer, alteraPraticaEsportes, alteraQualEsporte }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Etapa1)