import React, { Component } from "react"
import { Redirect } from "react-router-dom";

import Header from './includes/header'
import Footer from './includes/footer'
import Header_Evolucao from './includes/header_evolucao.jsx'

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getNoticias, setNoticia } from "../redux/noticiaReducer"


const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

class Blog extends Component {

    constructor(props){
        super(props)
    }

    componentWillMount(props) {
        this.props.getNoticias();
    }

    set_noticia(noticiaID){
        this.props.setNoticia(noticiaID)
    }

    render_noticias(noticias){
        if(noticias.length > 0){
            return noticias.map(noticia => (
                <div className="noticia">
                    <div className="imagem">
                        <img src={ URL + '/uploads/' + noticia.imagem } />
                    </div>
                    <div className="header">
                        <p className="date">{ noticia.data }</p>
                        <h2>{ noticia.titulo }</h2>
                        <h3>{ noticia.linha_fina }</h3>
                    </div>
                    <button onClick={ () => this.set_noticia(noticia.noticiaID) }>Ler Mais</button>
                </div>        
            ))
        } 
        return 
        
    }

    render() {
        if (this.props.noticias.redirect == 1) {
            return <Redirect to={'noticia'} />
        }
        return (
            <section className="main">
                <Header />
                <Header_Evolucao />
                <section className="blog">
                    
                { this.render_noticias(this.props.noticias.noticias) }            

                </section>
                <Footer ativo="blog" />
            </section>
        )
    }
}

function mapStateToProps(state){
    return{
        noticias: state.noticias
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ getNoticias, setNoticia }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)