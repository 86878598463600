import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Avatar from '../includes/avatar'
import Header from '../includes/header'
import Footer from '../includes/footer'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setAvaliacao } from "../../redux/treinoActions"
import { setLevelUp } from "../../redux/usuarioActions"
import { setNoticia } from "../../redux/noticiaReducer"
import { Animated } from "react-animated-css";

const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

class Exercicio_realizado extends Component {

    constructor(props) {
        super(props)
    }

    set_noticia(noticiaID) {
        this.props.setNoticia(noticiaID)
    }

    render_noticias(noticias) {
        return <div className="noticia">
            <div className="imagem">
                <img src={ URL + '/uploads/' + noticias[0].imagem} />
            </div>
            <div className="header">
                <p className="date">{noticias[0].data}</p>
                <h2>{noticias[0].titulo}</h2>
                <h3>{noticias[0].linha_fina}</h3>
            </div>
            <button onClick={() => this.set_noticia(noticias[0].noticiaID)}>Ler Mais</button>
        </div>
    }

    render() {
        if (this.props.noticias.redirect == 1) {
            return <Redirect to={'noticia'} />
        }

        if (this.props.treinos.treinos.length == 0) {
            return <Redirect to='/Dashboard' />
        }

        if (this.props.usuario.level_up == 1) {
            return (
                <section className="main">
                    <Header />
                    <section className="exercicio_realizado">
                        <div className="level_up">
                            <Animated animationIn="fadeInUp" animationOut="fadeOut">
                                <Avatar avatar={this.props.usuario.avatar} />
                                <h1>LEVEL UP <br /><span>CONGRATZ</span></h1>
                            </Animated>
                        </div>
                        <button className="default" onClick={() => this.props.setLevelUp(0)}>Próximo</button>
                    </section>
                    
                </section>
            )
        }

        /*
        if (this.props.usuario.anamnese == 1) {
            return <Redirect to={'anamnese'} />
        }
        */

        let avaliacao = null

        if (this.props.treinos.avaliacao == null) {
            avaliacao = <div className="avaliar_treino">
                <p>Como estou após o treino:</p>
                <img src="nota_1.png" onClick={() => this.props.setAvaliacao(1)} />
                <img src="nota_2.png" onClick={() => this.props.setAvaliacao(2)} />
                <img src="nota_3.png" onClick={() => this.props.setAvaliacao(3)} />
            </div>
        }



        return (
            <section className="main">
                <Header />
                <section className="exercicio_realizado">
                    <div className="back">
                        <Link to="./treinos"><FontAwesomeIcon icon={faChevronLeft} /><p>Voltar aos Treinos</p></Link>
                    </div>

                    <h1>TREINO <br />CONLUÍDO</h1>
                    <p>Você completou o seu treino, parabéns!</p>
                    <Avatar avatar={ this.props.usuario.avatar } />
                    <p className="pontos">+{this.props.treinos.xp} pts</p>

                    {avaliacao}

                    <section className="blog">
                        {this.render_noticias(this.props.noticias.noticias)}
                    </section>

                </section>
                <Footer ativo="treinos" />
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario,
        treinos: state.treinos,
        noticias: state.noticias
    }
}

function mapDipsatch(dispatch) {
    return bindActionCreators({ setAvaliacao, setLevelUp, setNoticia }, dispatch);
}

export default connect(mapStateToProps, mapDipsatch)(Exercicio_realizado)