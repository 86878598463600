import React from "react"
import { Redirect } from "react-router-dom"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Animated } from "react-animated-css";

import Header from './header'
import Vimeo from '@u-wave/react-vimeo'
import { setAbdominal, setAgachamento, setPeso, sendAnamnese } from '../../redux/anamneseReducer'
import { setAnamnese } from '../../redux/usuarioActions'


import { ToastContainer, toast } from 'react-toastify';

function Anamnese(props) {

    function finalizar() {
        let valida = true
        if((props.anamnese.agachamento == 0) || (props.anamnese.agachamento == '')){
            toast.error("Preencha quantos agachamentos você conseguiu fazer")
            valida = false
        }
        if((props.anamnese.abdominal == 0) || (props.anamnese.abdominal == '')){
            toast.error("Preencha quantos abdominais você conseguiu fazer")
            valida = false
        }
        if((props.anamnese.peso == 0) || (props.anamnese.peso == '')){
            toast.error("Preencha seu peso atual")
            valida = false
        }
        if(valida === true){
            toast.success("Anamnese Concluida");
            props.sendAnamnese()
        }
    }

    console.log(props.usuario.anamnese)

    if (props.usuario.anamnese == 0) {
        return <Redirect to={'dashboard'} />
    }

    return (
        <section className="main_login">
            <Animated animationIn="fadeInUp" animationOut="fadeOut">
            <section className="anamnese">
                <h1>Teste Físico</h1>
                <p>Este teste tem como objetivo avaliar a força de resistência dos músculos das regiões  abdominal e coxa.<br />
                O usuário deverá realizar o maior número de repetições completas em 1 minuto.<br />
                E ao final, registrar os números para acompanharmos a evolução.</p>
                <Vimeo video="631339894" responsive={true} controls={true} />

                <section className="form">
                    <fieldset>
                        <label>Agachamento</label>
                        <input type="text"  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}  placeholder="0" value={props.anamnese.agachamento} onChange={(e) => props.setAgachamento(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label>Abdominal</label>
                        <input type="text"  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}  placeholder="0" value={props.anamnese.abdominal} onChange={(e) => props.setAbdominal(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label>Peso Atual</label>
                        <input type="text"  onKeyPress={(event) => { if (!/[.,0-9]/.test(event.key)) { event.preventDefault(); } }}  placeholder="0" value={props.anamnese.peso} onChange={(e) => props.setPeso(e.target.value)} />
                    </fieldset>
                    <button className="default" onClick={ () => finalizar() }>FINALIZAR</button>
                </section>
            </section>
            </Animated>
            <ToastContainer />
        </section >

    )
}

function mapStateToProps(state) {
    return {
        anamnese: state.anamnese,
        usuario: state.usuario
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setAnamnese, setAbdominal, setAgachamento, setPeso, sendAnamnese }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Anamnese)