import React, { useState, Component } from 'react'
import { Redirect } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faFire } from '@fortawesome/free-solid-svg-icons'
import { faRunning } from '@fortawesome/free-solid-svg-icons'


import Modal from 'react-bootstrap/Modal'
import Vimeo from '@u-wave/react-vimeo'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { cleanState, setVisibility, treinoRealizado, setDataInicio, setDataFim, treinoIniciado, treino_atualizado, getTreinos } from "../../redux/treinoActions"
import { checkLogin } from "../../redux/usuarioActions"
import { Animated } from "react-animated-css";


const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

class Exercicios extends Component {

    state = { redirect: null, body_show: 0, visivel: false };
    body_html = null

    constructor(props) {
        super(props)
    }

    play(treinoID) {
        this.props.cleanState()
        this.setVisibilidade(true)
        this.props.treinoIniciado(treinoID, Date.now())
        console.log('Diração:' + this.props.treino.duracao)
        console.log('Porcentagem:' + parseInt(this.props.treinos.porcentagem * 100) )
    }

    videoPaused(e) {
        console.log(e)
    }

    onTimeUpdate(e) {
        this.props.treino_atualizado(e.percent);
        //this.props.setDuration(this.props.treino.treinoID, e.percent)
    }

    videoEnded(e) {
        this.props.setVisibility(false)
        this.props.treinoRealizado(Date.now());
        this.changeView()
    }

    changeView() {
        //this.props.checkLogin() 
        this.setState({ redirect: "exercicio_realizado" });
    }

    setVisibilidade = (value) => {
        if(value == false){
            // estamos fechando o vídeo, então atualizando os treinos para carregar as porcentagens do banco de dados.
            this.props.getTreinos();
        }
        this.setState({ redirect: null, body_show: 1, visivel: value })
    }

    body_show = () => {
        if (this.state.body_show == 0) {
            this.setState({ redirect: null, body_show: 1 })
        } else {
            
            this.setState({ redirect: null, body_show: 0 })
        }

    }

    /*
    body_content_render = () => {
        if (this.state.body_show == true) {
            return this.body_html = <section className="body">
                <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={this.state.body_show}>
                    <img src="./play.png" className="play" onClick={() => this.play()} />
                    <img src={URL + 'uploads/' + this.props.treino.cover} className="cover" />
                </Animated>
            </section>
        } else {
            return null;
        }
    }
    */

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <section className="exercicio">
                <Modal size="xl" show={this.state.visivel} onHide={() => this.setVisibilidade(false)} centered="true" fullscreen="xxl-down" aria-labelledby="example-modal-sizes-title-sm" className="ModalVideo">
                    <Modal.Body>
                        <Vimeo video={this.props.treino.video_path} autoplay responsive={true} start={this.props.treino.duracao * (this.props.treino.porcentagem / 100)} controls={true} onPause={(e) => this.videoPaused(e)} onEnd={(e) => this.videoEnded(e)} onTimeUpdate={(e) => this.onTimeUpdate(e)} />
                        <br />
                        <h2>{this.props.treino.nome}</h2>
                        <p>{this.props.treino.descricao}</p>
                        <div className="features">
                            <div>
                                <img src={URL + 'uploads/' + this.props.treino.icone} className="icone" />
                                <p>{this.props.treino.info}</p>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faClock} />
                                <p>{parseInt(this.props.treino.duracao / 60)} m</p>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faFire} />
                                <p>{this.props.treino.calorias} cal</p>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faClock} />
                                <p>{ parseInt(this.props.treinos.porcentagem * 100) }%</p>
                            </div>
                        </div>
                        <button className="default" onClick={() => this.setVisibilidade(false)} >Fechar Treino</button>
                    </Modal.Body>
                </Modal>

                { /* this.body_content_render() */}
                <section className="body">
                    <img src="./play.png" className="play" onClick={() => this.play(this.props.treino.treinoID)} />
                    <img src={URL + 'uploads/' + this.props.treino.cover} className="cover" />
                </section>

                <section className="header" onClick={() => this.body_show()} >
                    <h2>{this.props.treino.nome}</h2>
                    <div className="features">
                        <div>
                            <img src={URL + 'uploads/' + this.props.treino.icone} className="icone" />
                            <p className="info">{this.props.treino.info}</p>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faClock} />
                            <p>{parseInt(this.props.treino.duracao / 60)} m</p>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faFire} />
                            <p>{this.props.treinos.calorias} cal</p>
                        </div>


                    </div>
                    <div className="percentage">
                        <p>{this.props.treino.porcentagem}%</p>
                    </div>
                </section>

            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario,
        treinos: state.treinos,
        visibility: state.treinos.visibility
    }
}

function mapDipsatch(dispatch) {
    return bindActionCreators({ cleanState, setVisibility, treinoRealizado, setDataInicio, setDataFim, checkLogin, treino_atualizado, treinoIniciado, getTreinos }, dispatch);
}

export default connect(mapStateToProps, mapDipsatch)(Exercicios)