import React from "react"
import ProgressBar from 'react-bootstrap/ProgressBar'

import { connect } from 'react-redux'

function Evolucao(props) {
    return (
        <section className="evolucao">
            <section className='line'>
                <div className="porcentagem">{props.usuario.porcentagem_next_level}%</div>
                <div className="level">Level: {props.usuario.level}</div>
            </section>
            <ProgressBar now={props.usuario.porcentagem_next_level} />
            <p>Faltam {props.usuario.xp_next_level} pontos para o próximo nível.</p>
        </section>
    )
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario
    }
}

export default connect(mapStateToProps)(Evolucao)