import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Header from '../includes/header'
import Footer from '../includes/footer'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getHistoricoTreinos } from "../../redux/treinoActions"

const URL = "https://api.moveonsaude.com.br/"
//const URL = "http://localhost:8080/"

class Historico_treinos extends Component {

    constructor(props) {
        super(props)
    }

    render_historico_treinos(treinos) {
        const historico = treinos.historico        
        if (historico.length > 0) {
            return historico.map(treino => (
                <article className="exercicio">
                    <h2>{treino.nome}</h2>
                    <div className="info">
                        <img src={URL + '/uploads/' + treino.cover} className="cover" />
                    </div>
                    <div className="info">
                        <p>{treino.data}</p>
                        <p>XP: {treino.xp}</p>
                    </div>
                    <div className="info">
                        <p>Moeda: {treino.moeda_1}</p>
                    </div>
                </article>
            ))
        }
    }

    componentWillMount(props) {
        this.props.getHistoricoTreinos();
    }

    render() {
        return (
            <section className="main">
                <Header />
                <section className="historico_treinos">
                    <div className="back">
                        <Link to="./treinos"><FontAwesomeIcon icon={faChevronLeft} /><p>Voltar aos Treinos</p></Link>
                    </div>

                    <h1>Histórico de Treinos</h1>
                    <div className="exercicios">
                        {this.render_historico_treinos(this.props.treinos)}
                    </div>


                </section>
                <Footer ativo="treinos" />
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        usuario: state.usuario,
        treinos: state.treinos,
        noticias: state.noticias
    }
}

function mapDipsatch(dispatch) {
    return bindActionCreators({ getHistoricoTreinos }, dispatch);
}

export default connect(mapStateToProps, mapDipsatch)(Historico_treinos)