import React from "react"

import "../App.css"
import { Animated } from "react-animated-css";

import Etapa1 from './cadastro/etapa1'
import Etapa2 from './cadastro/etapa2'
import Etapa3 from './cadastro/etapa3'

function Cadastro(props) {

    const [etapa, setEtapa] = React.useState(1)

    


    if (etapa === 1) {
        return (
            <Etapa1 mudaTela={ setEtapa } />
        )
    } else if(etapa === 2){
        return (
            <Etapa2 mudaTela={ setEtapa } />
        )
    } else if(etapa === 3){
        return (
            <Etapa3 mudaTela={ setEtapa } />
        )
    }

}
export default Cadastro